import { useContext, useState } from "react";
// import { Container } from "../style";

import VerificationCode from "react-input-verification-code";
import { Countdown } from "../../../../Components/Molecules";
import { ButtonsContainer } from "../../../../Components/Molecules/HeaderContent/styles";
import { Button, CompanyLogo } from "../../../../Components/Atoms";
import { VerificationContainer } from "../../VerificationCode/styles";
import { MyFirstAccessContext } from "..";
import { Redirect, useHistory } from "react-router-dom";
import { useToast } from "../../../../Hooks/toast";

export const ValidateCode = () => {

    const { email, sendVerificationCode, validateVerificationCode } = useContext(MyFirstAccessContext);

    const history = useHistory();

    const { addToast } = useToast();

    const [isLoading, setIsLoading] = useState(false);

    const [code, setCode] = useState('');
    const [expired, setExpired] = useState(true);

    const resendCode = async () => {
        setIsLoading(true);
        await sendVerificationCode(email);
        setIsLoading(false);
    }

    const handleSubmit = async () => {
        setIsLoading(true);
            
        const isSuccessful = await validateVerificationCode(email, code);

        setIsLoading(false);

        if(!isSuccessful) {
            return addToast({
                title: 'Erro',
                description: 'Erro ao validar código de acesso',
                type: 'error'
            })
        }

        history.push('/auth/my-first-access/change-password')
    }

    return (
        <VerificationContainer>
            <CompanyLogo width={200} height={60} />
            <p>
                Para sua segurança, insira o código de verificação enviada para o número cadastrado
            </p>
            <div className="custom-styles" >
                <VerificationCode
                    onChange={setCode}
                    value={code}
                    length={6}
                    placeholder=""
                />
            </div>
            <Countdown
                expireTime={45}
                resendCode={(type: string) => {
                    resendCode();
                }}
                isExpired={setExpired}
            />
            <ButtonsContainer>
                <Button
                    onClick={handleSubmit}
                    loading={isLoading}
                    disabled={expired || code.length !== 6}
                >
                    Verificar
                </Button>
            </ButtonsContainer>
        </VerificationContainer>
    )
}