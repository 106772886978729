import styled from "styled-components";

interface IProps {
  isActive?: boolean;
  primaryColor?: string;
  secondaryColor?: string;
  backgroundColor?: string;
}

export const LoginFormContent = styled.div<IProps>`
  min-height: 95%;

  display: flex;
  justify-content: center;
  flex-direction: column;

  img {
    margin-bottom: 20px;
  }

  p {
    font-size: 14px;
  }

  h1 {
    text-align: left;
    font-size: 20px;
    margin-bottom: 10px;
    color: #364a63;
  }

  form {
    margin-top: 20px;
    display: flex;
    width: 100%;
    align-self: center;
    flex-direction: column;

    div {
      input,
      label {
        font-size: 1.4em;
      }
    }
  }

  a {
    align-self: flex-end;
    margin-top: 10px;
    cursor: pointer;
    font-size: 14px;

    :hover {
      color: ${(props) => props.theme.primary.light};
    }
  }
`;