import styled, { keyframes, css } from "styled-components";
import { Breadcrumbs } from "@mui/material";

interface IProps {
  isActive?: boolean;
  primaryColor?: string;
  secondaryColor?: string;
  backgroundColor?: string;
}

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
  width: 100%;

  @media (max-width: 768px) {
    justify-content: center;
    height: 100vh;
    width: 100vw;
    position: fixed;
  }
  a:visited,
  a:hover,
  a:active {
    color: inherit;
  }
  a {
    text-decoration: none;
  }
`;

export const Content = styled.div`
  display: flex;
  overflow-y: hidden;
  justify-content: center;
  flex: 1;
  width: 100%;
`;

export const LoginFormContainer = styled.div<IProps>`
  padding: 3.2em;
  flex: 1 35%;
  height: 100%;
  justify-self: center;
  align-self: center;
`;

export const LoginCarroselContent = styled.div<IProps>`
  @media (max-width: 812px) {
    display: none;
  }

  background-size: cover;
  flex: 1 70%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  section {
    background: #444;
    width: 100%;
    height: 100%;
    z-index: 10;
    opacity: 0.2;
  }

  h4 {
    text-shadow: 0px 1px 1px black;
    color: white;
  }

  ${(props) =>
    props &&
    css`
      background-color: ${(props) => props.theme.background?.original};
    `}

  span {
    color: ${(props) => props.theme.secondary?.secondary_02};
  }
`;

export const CustomBreadcrumbs = styled(Breadcrumbs)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    vertical-align: middle;
    font-size: 14px;
  }

  p {
    cursor: pointer;
    font-size: 14px;

    :hover {
      color: ${(props) => props.theme.primary.light};
    }
  }

  @media (max-width: 768px) {
    position: absolute;
    bottom: 20px;
  }
`;
