import { MoreVert, DeleteOutline } from "@mui/icons-material";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Typography,
  Chip,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useToast } from "../../../../Hooks/toast";
import useCallAxios from "../../../../Hooks/useCallAxios";
import CustomButton from "../CustomButton";
import CodeIcon from "@mui/icons-material/Code";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import api from "../../../../Services/api";

interface SubaccountApiKeyResponse {
  active: boolean
  apikey: string
  apisecret: string
  created_at: string
  password: string
  username: string
}

const ApiTableList = ({ handleCopy, subAccountId }) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const [apiKeys, setApiKeys] = useState<SubaccountApiKeyResponse[]>([]);

  const tooltipOpen = Boolean(anchorEl);

  const getAll = async () => {
    const res = await api.get(`/subaccount/${subAccountId}/api`);
    setApiKeys(res.data);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getAll();
  }, [subAccountId])


  const createApi = async () => {
    await api.post(`/subaccount/${subAccountId}/api`, { afterId: "api" })
    await getAll();
  }

  return (
    <>
      <CustomButton
        fullWidth
        startIcon={<CodeIcon />}
        onClick={() => createApi()}
      >
        Criar uma nova chave
      </CustomButton>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>API key</TableCell>
            <TableCell>API Secret</TableCell>
            <TableCell>Nome de usuário</TableCell>
            <TableCell>Senha</TableCell>
            <TableCell>Data criação</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            apiKeys.length === 0 && (
              <TableRow>
                <TableCell align="left" width={10} colSpan={10}>
                  Nenhuma chave foi adicionada
                </TableCell>
              </TableRow>
            )
          }
          {
            apiKeys.map(row => (
              <TableRow key={row.apikey}>
                <TableCell align="left" width={10}>
                  <IconButton id={row.apikey} onClick={handleClick}>
                    <MoreVert />
                  </IconButton>
                  <Menu
                    sx={{
                      display: anchorEl?.id !== row.apikey ? "none" : "block",
                    }}
                    key={row.apikey}
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={tooltipOpen}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        width: "25ch",
                        zIndex: 9999999999,
                      },
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 2,
                        }}
                      >
                        <DeleteOutline fontSize="inherit" />
                        Remover chave
                      </Box>
                    </MenuItem>
                  </Menu>
                </TableCell>

                <TableCell>
                  <Typography
                    fontFamily="monospace !important"
                    fontSize="14px"
                  >
                    {row.apikey}
                    <IconButton
                      onClick={() => handleCopy(row.apikey)}
                      disabled={!row.apikey}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </Typography>
                </TableCell>
                <TableCell>
                  {" "}
                  <Typography
                    fontFamily="monospace !important"
                    fontSize="14px"
                  >
                    {row.apisecret}
                    <IconButton
                      onClick={() => handleCopy(row.apisecret)}
                      disabled={!row.apisecret}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </Typography>
                </TableCell>
                <TableCell>{row.username}</TableCell>
                <TableCell>{row.password}</TableCell>
                <TableCell>
                  {new Date(row.created_at).toLocaleDateString()}
                </TableCell>
                <TableCell align="center">
                  {row.active ? (
                    <Chip label="Ativo" color="primary" />
                  ) : (
                    <Chip label="Inativo" color="error" variant="outlined" />
                  )}
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </>
  );
};

export default ApiTableList;
