import React, { useContext } from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { PrivateApp } from "../Components/Templates";
import { physicalPerson } from "../Constants/RoutesByType";

import { AuthContext } from "../Contexts/Auth";

import { PublicTemplate } from "../Pages/Public/Template";

const Routes = () => {
  const [stateAuthContext] = useContext(AuthContext);
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/auth'>
          <PublicTemplate/>
        </Route>
        <Route path='/'>
          <PrivateApp routes={physicalPerson.private} />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
