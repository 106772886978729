import React, { useRef, useCallback, useState, ChangeEvent } from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import {
  LoginFormContent,
} from "./styles";
import { Input, Button } from "../../../Components/Atoms";
import { handleTaxIDLoginInput } from "../../../Utils/masks";
import { AuthContext } from "../../../Contexts/Auth";
import { useContext } from "react";
import getValidationErrors from "../../../Utils/getValidationErrors";
import { useLogin } from "../../../Hooks";

interface ILoginFormData {
  password: string;
  username: string;
}

const authSchema = Yup.object().shape({
  username: Yup.string()
    .required("CPF/CNPJ obrigatório."),
  password: Yup.string().min(
    8,
    "Senha obrigatória, no mínimo 8 digitos."
  ),
});

const Login: React.FC = () => {
  //SET CAPTCHA FAZ A REQ DE LOGIN
  const formRef = useRef<FormHandles>(null);
  const [inputMask, setInputMask] = useState("");
  const [stateAuthContext, dispatchAuthContext] = useContext(AuthContext);

  const { fetchLogin, requestLoading } = useLogin();

  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: ILoginFormData) => {
      try {
        await authSchema.validate(data, {
          abortEarly: false,
        });

        await fetchLogin({
          username: data.username,
          password: data.password
        });

        return history.push('/auth/verification-code');

      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    []
  );

  return (
    <LoginFormContent>
      <h1>Bem vindo!</h1>
      <span> Para acessar, por favor faça o login.</span>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Input
          value={inputMask}
          type="text"
          name="username"
          placeholder="CPF ou CNPJ ou Email"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setInputMask(handleTaxIDLoginInput(e.currentTarget.value));
          }}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Link
            to="/auth/my-first-access"
          >
            Primeiro Acesso?
          </Link>
          <Link
            to="/"
          >
            esqueceu sua senha?
          </Link>
        </div>
        <Input
          id="passwordInput"
          name="password"
          placeholder="Senha"
          type="password"
        />

        <Button type="submit" loading={requestLoading}>
          Entrar
        </Button>
      </Form>
    </LoginFormContent>
  )
};

export default Login;
