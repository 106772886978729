import { Route, Switch, useRouteMatch } from "react-router-dom"
import { SendEmail } from "./SendEmail";
import { ValidateCode } from "./ValidateCode";
import { ChangePassword } from "./ChangePassword";
import { createContext, useState } from "react";
import api from "../../../Services/api";

interface MyFirstAccessContextProps {
    email: string,
    sendVerificationCode: (email: string) => Promise<boolean>
    validateVerificationCode: (email: string, code: string) => Promise<boolean>
    changePassword: (email: string, password: string) => Promise<boolean>
}

export const MyFirstAccessContext = createContext<MyFirstAccessContextProps>({} as MyFirstAccessContextProps);

export const MyFirstAccess = () => {

    const isDebug = false;

    let { path } = useRouteMatch();

    const [email, setEmail] = useState('');

    const sendVerificationCode = async (email = '') => {
        if(isDebug) return true;

        try {
           await api.post(`/Operators/checkbyemail/step/1`, {
                email
            });

            setEmail(email);

            return true;
        }
        catch (error){
            if (error.response && error.response.data&& error.response.data.description) 
                return error.response.data.description;
            
            return false;
        }
    }

    const validateVerificationCode = async (email = '', code = '') => {
        if(isDebug) return true;

        try {
            await api.post(`/Operators/checkbyemail/step/2`, {
                email,
                code
            }); 

            return true;
        }   
        catch {
            return false;
        }
    }

    const changePassword = async (email = '', password = '') => {
        if(isDebug) return true;

        try {
            await api.post(`/Operators/checkbyemail/step/3`, {
                email,
                password
            });

            return true;
        }
        catch {
            return false;
        }
    }

    return (
        <MyFirstAccessContext.Provider value={{ 
            email, 
            sendVerificationCode, 
            validateVerificationCode,
            changePassword
        }}>
            <Switch>
                <Route exact path={path}>
                    <SendEmail />
                </Route>
                <Route path={`${path}/validate-code`}>
                    <ValidateCode />
                </Route>
                <Route path={`${path}/change-password`}>
                    <ChangePassword />
                </Route>
            </Switch>
        </MyFirstAccessContext.Provider>

    )
}