import { ArrowBack } from "@mui/icons-material";
import React, { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import { CompanyLogo, Button } from "../../../../Components/Atoms";
import { Grid, Input, TextField, Typography } from "@mui/material";
import { useToast } from "../../../../Hooks/toast";
import { VerificationContainer } from "../../ResetPassword/styles";
import { MyFirstAccessContext } from "..";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const ChangePassword: React.FC = () => {
    const history = useHistory();

    const [password, setPassword] = useState("");
    const [confirmationPassword, setConfirmationPassword] = useState("");
    const [errors, setErrors] = useState({} as Record<string, string>);
    const [disabled, setDisabled] = useState(false);

    const timeoutRef = useRef<any>(undefined);

    const { email, changePassword } = useContext(MyFirstAccessContext);

    const { addToast } = useToast();

    const validate = () => {
        const errors = {} as Record<string, string>;

        if(!password) errors.password = 'Obrigatório';
        else if(password.length < 8) errors.password = 'Senha deve conter oito caractéres';
        else if(confirmationPassword !== password)  errors.confirmationPassword = 'As senhas precisam ser iguais';

        if(!confirmationPassword) errors.confirmationPassword = 'Obrigatório'
        else if(confirmationPassword.length < 8) errors.confirmationPassword = 'Senha deve conter oito caractéres';
        else if(confirmationPassword !== password)  errors.confirmationPassword = 'As senhas precisam ser iguais';

        return errors;
    }
    

    useEffect(() => {
        return () => {
            clearTimeout(timeoutRef.current);
        }
    }, [])

    const handleSubmit = async () => {

        const errors = validate();

        setErrors(errors);

        if(Object.keys(errors).length > 0) return;

        setDisabled(true);

        const isSuccessful = await changePassword(email, password);

        if(!isSuccessful) {
            setDisabled(false);
            return addToast({
                title: 'Erro',
                description: 'Erro ao mudar a senha da sua conta.',
                type: 'error'
            })
        }

        addToast({
            title: 'Sucesso',
            description: 'Senha resetada com sucesso, você será redirecionado para fazer login',
            type: 'success'
        })

        timeoutRef.current = setTimeout(() => {
            history.push('/auth');
        }, 5000);
    }

    return (
        <VerificationContainer>
            <CompanyLogo width={200} height={60} />
            <p>Crie sua nova senha</p>
            <p>Informe abaixo sua nova senha de acesso:</p>
            <Grid
                container
                spacing={2}
                flexDirection="column"
                alignItems="center"
            >
                <Grid item>
                    <TextField
                        fullWidth
                        error={!!errors.password}
                        helperText={errors.password}
                        value={password}
                        type="password"
                        name="password"
                        placeholder="Senha"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setPassword(e.currentTarget.value);
                        }}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        fullWidth
                        error={!!errors.confirmationPassword}
                        helperText={errors.confirmationPassword}
                        value={confirmationPassword}
                        type="password"
                        name="confirmationPassword"
                        placeholder="Confirmar senha"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setConfirmationPassword(e.currentTarget.value);
                        }}
                    />
                </Grid>
                <Grid item>
                    <Button disabled={disabled} onClick={handleSubmit}>
                        Redefinir senha
                    </Button>
                </Grid>
            </Grid>
        </VerificationContainer>
    );
};