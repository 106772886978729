import { useSnackbar } from "notistack";
import React, { useContext } from "react";
import { useCallback } from "react";
import VerificationCode from "react-input-verification-code";
import { useHistory } from "react-router-dom";
import { CompanyLogo } from "../../../Components/Atoms";
import { AuthContext } from "../../../Contexts/Auth";
import { useLogin } from "../../../Hooks";
import { useCaptcha } from "../../../Hooks/useCaptcha";
import { Button } from "../../../Components/Atoms";
import { Countdown } from "../../../Components/Molecules";
import { VerificationContainer, ButtonsContainer } from "./styles";

// import MetaDecorator from "../../components/MetaDecorator";

const VerificationCodeLogin: React.FC = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [code, setcode] = React.useState("");
  const [verificationDisable, setVerificationDisable] = React.useState(false);
  const [stateAuthContext, dispatchAuthContext] = useContext(AuthContext);
  const { firstStepData } = stateAuthContext;
  const { last_number } = firstStepData;
  const { setCaptcha } = useCaptcha();

  const {
    requestError: validateError,
    requestLoading,
    fetchValidateTwoStepToken,
    // fetchLogin,
  } = useLogin();

  const handleValidatingCode = useCallback(() => {
    if (code.includes("·")) {
      enqueueSnackbar("Código inválido ou incompleto", {
        variant: "error",
      });
    }
    fetchValidateTwoStepToken({
      password: firstStepData.password,
      username: firstStepData.username,
      code,
    });
  }, [firstStepData, code]);

  const resendCode = useCallback(
    (type) => {
      type === "email"
        ? setCaptcha({
            type,
            username: firstStepData.username,
            password: firstStepData.password,
          })
        : setCaptcha({
            username: firstStepData.username,
            password: firstStepData.password,
          });
      setVerificationDisable(false);
    },
    [firstStepData]
  );
  return (
    <VerificationContainer>
      <CompanyLogo width={170} height={50} />
      <p>Verificação em duas etapas</p>
      <p>
        Para sua segurança, insira o código de verificação enviada para o número
        de telefone {last_number}
      </p>
      {/* onkeydown={handleKeyDown2} */}
      <div className="custom-styles" >
        <VerificationCode
          onChange={setcode}
          value={code}
          length={6}
          placeholder=""
        />
      </div>
      <Countdown
        expireTime={45}
        resendCode={(type: string) => {
          resendCode(type);
        }}
        isExpired={(isExpired: boolean) => {
          setVerificationDisable(isExpired);
        }}
      />
      <ButtonsContainer>
        <Button
          action={"return"}
          onClick={() => {
            history.push("/auth");
            dispatchAuthContext({
              type: "clear",
            });
          }}
        >
          Voltar
        </Button>
        <Button
          onClick={handleValidatingCode}
          loading={requestLoading}
          disabled={verificationDisable}
        >
          Verificar
        </Button>
      </ButtonsContainer>
    </VerificationContainer>
  );
};

export default VerificationCodeLogin;
