import React from "react";
import PropTypes from "prop-types";
import { Card, useTheme } from "@mui/material";

export const ScreenNotification = ({ icon, text }) => {

  const theme = useTheme();
  return (
    <Card
      style={{
        height: 50,
        alignItems: "center",
        display: "flex",
        padding: 10,
      }}
    >
      <p
        style={{
          color: theme.palette.primary.main,
          fontSize: "small",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "100%",
        }}
      >
        {icon}
        <span>{text}</span>
      </p>
    </Card>
  );
};

ScreenNotification.propTypes = {};
