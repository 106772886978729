import {
  ButtonBase,
  Grid,
  Button
} from "@mui/material";
import { ScreenGreeting } from "../../../Components/Molecules";
import intl from "react-intl-universal";
import { OrganizerCard } from "./Card";
import {
  useSubAccountsList,
} from "../../../Hooks/useSubAccounts";
import { useEffect, useRef, useState } from "react";
import { useToast } from "../../../Hooks/toast";
import { useEventListener } from "../../../Hooks";
import { OrganizerForm, OrginizerFormRef } from "./OrganizerForm";
import { Container } from "../Pix/styles";
import { Link } from "react-router-dom";



export function Organizers() {
  

  const organizerFormRef = useRef<OrginizerFormRef>(null);

  const {
    handleGET: fetchSubAccountsGET,
    loading,
    state: subAccounts,
  } = useSubAccountsList();

  useEffect(() => {
    fetchSubAccountsGET({});
  }, []);

  return (
    <Container>
      
      <ScreenGreeting
        title={intl.get(`words.organizers`)}
        subTitle="Faça o gerenciamento dos seus pontos de venda com facilidade."
      />

      <OrganizerForm
        onSucess={() => fetchSubAccountsGET({})}
        ref={organizerFormRef}
      />
      <Button variant="contained" sx={{ mb: 2 }} onClick={() => organizerFormRef.current.openOrganizer({ name: '', subAccountId: '', isActive: true })}>
        Criar Ponto de Venda
      </Button>

      <Grid container spacing={2}>
        {Array.isArray(subAccounts) &&
          (subAccounts.length === 0 ? (
            <p> Não há organizador cadastrado. </p>
          ) : (
            subAccounts.map((subAccount, i) => (
              <Grid item key={subAccount.subAccountId}>
                <Link
                  to={`/pontos-de-venda/${subAccount.subAccountId}`}
                >
                  <OrganizerCard
                    key={i}
                    name={subAccount.name}
                    upperBarColor="#36D9FF"
                    statusColor={subAccount.isActive ? '#4CB592' : '#dc3545'}
                    totalValue={subAccount.available}
                  />
                </Link>
              </Grid>
            ))
          ))}
      </Grid>
    </Container>

  );
}
