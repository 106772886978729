import {  Typography } from "@mui/material";
import { CompanyLogo } from "../../../Components/Atoms";
import { Container, Content, CustomBreadcrumbs, LoginCarroselContent, LoginFormContainer } from "./style";
import TypeWriter from "typewriter-effect";
import { PrivacyPolicyModal } from "../Privacy";
import { useCallback, useContext, useState } from "react";
import { loadItem } from "../../../Utils";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import Login from "../Login";
import VerificationCodeLogin from "../VerificationCode";
import ForgotPassword from "../ForgotPassword";
import GlobalStyle from "../styles/GlobalStyle";
import { AuthContext } from "../../../Contexts/Auth";
import ResetPassword from "../ResetPassword";
import { MyFirstAccess } from "../MyFirstAccess";

export const PublicTemplate = () => {

    const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
    const { title, app, login_image, site } = loadItem("Options");

    const handleClose = useCallback(() => {
        setOpenPrivacyPolicy(!openPrivacyPolicy);
    }, [openPrivacyPolicy]);   

    const [stateAuthContext] = useContext(AuthContext);

    if(stateAuthContext.token) {
        return <Redirect to='/' />
    }

    let { path } = useRouteMatch();

    return (
        <Container>
            <Content>
                <LoginCarroselContent>
                    <section />
                    <div
                        style={{
                            zIndex: 1000,
                            position: "absolute",
                            marginLeft: 40,
                            marginTop: 40,
                        }
                        }
                    >
                        <CompanyLogo width={270} height={70} isWhiteLogo={true} />
                    </div >

                    <div
                        style={{
                            zIndex: 1000,
                            position: "absolute",
                            top: "40%",
                            display: "flex",
                            marginLeft: 40,
                            flexDirection: "column",
                            width: "50%",
                        }}
                    >
                        <h4
                            style={{
                                fontSize: "1.5em",
                                marginBottom: "1.2em",
                            }}
                        >
                            A melhor solução em plataforma de pagamento,
                        </h4>
                        <Typography style={{ fontSize: "3em" }} variant="h4">
                            Simples e completa, agora na web.
                            <span>
                                <TypeWriter
                                    options={{
                                        strings: ["Simples", "Rápida", "Completa"],
                                        autoStart: true,
                                        loop: true,
                                    }}
                                />
                            </span>
                            pra você turbinar seu negócio
                        </Typography>
                    </div>
                </LoginCarroselContent>
                <LoginFormContainer>
                    <Switch>
                        <Route exact path={path}>
                            <Login />
                        </Route>
                        <Route path={`${path}/verification-code`}>
                            <VerificationCodeLogin  />
                        </Route>
                        <Route path={`${path}/reset-password`}>
                            <ResetPassword  />
                        </Route>
                        <Route path={`${path}/my-first-access`}>
                            <MyFirstAccess  />
                        </Route>
                        <Route path={`${path}/*`}>
                            <Redirect to={path} />
                        </Route>

                    </Switch>
                    <CustomBreadcrumbs separator={false}>
                        <p
                            onClick={() => {
                                window.open(site);
                            }}
                        >
                            {title}
                        </p>
                        <p
                            onClick={() => {
                                setOpenPrivacyPolicy(true);
                            }}
                        >
                            Política de privacidade
                        </p>
                    </CustomBreadcrumbs>
                </LoginFormContainer>
            </Content >
            <GlobalStyle/>
            <PrivacyPolicyModal open={openPrivacyPolicy} onClose={handleClose} />
        </Container >
    );
}