import { Box, Grid } from "@mui/material";
import styled from "styled-components";

export const OrganizerCardContainer = styled(Box)`
  padding: 16px;
  border-radius: 15px;
  border: 1px solid #d3e1f5;
  background-color: #fafbfc;
`;

export const OrganizerCardDetail = styled(Box)`
  padding: 16px;
  border-radius: 15px;
  border: 1px solid #d3e1f5;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  
  width: 100%;
  justify-content: space-between;

  h6 {
    font-size: 18px;
    font-weight: 400;
  }
  p {
    width: fit-content;
    color: #777;
    cursor: pointer;

    & svg {
      margin-right: 5;
      vertical-align: middle;
      font-size: 20;
    }

    :hover {
      color: #0096ff;
      transition: 1s;
    }
  }
  button {
    width: fit-content;
    color: #777;
    border: none;

    & svg {
      margin-right: 5;
      vertical-align: middle;
      font-size: 20;
    }

    :hover {
      color: #0096ff;
      transition: 1s;
    }
  }
`;