import React, { useEffect, useState } from "react";
import { ModalLoader } from "../../../Components/Atoms/ModalLoader";
import {
  Paper,
  Typography,
  Collapse,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  IconButton,
  Menu,
  MenuItem,
  Box,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  Chip,
  Link as MuiLink,
} from "@mui/material";

import {
  Edit,
  MoreVert,
  ToggleOn,
  ToggleOff,
  Dialpad,
  Tag,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { ScreenGreeting } from "../../../Components/Molecules";
import { TabButton, TabContainer } from "../../../Styles/GlobalStyles";
import useCallAxios from "../../../Hooks/useCallAxios";
import { Form, Formik } from "formik";
import { string, object } from "yup";
import { Button, LinkButton } from "../../../Components/Atoms";
import { useToast } from "../../../Hooks/toast";
import { useSubAccountsList } from "../../../Hooks/useSubAccounts";
import { Link, useHistory } from "react-router-dom";
import { FiExternalLink } from "react-icons/fi";
import ModalPin, { useModalPin } from "./ModalPin";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    maxWidth: "90%",
    minHeight: "100%",
    margin: "0px auto",
  },
  paper: {
    minWidth: "100%",
    marginBottom: 20,
    marginTop: 20,
    padding: 10,
  },
}));

export const useOperators = ({ subAccountId }) => {
  const history = useHistory();
  const [tab, handleTab] = useState("operators-list");
  const [operatorsList, setOperatorsList] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState(null);

  const context = "operatorsList";

  const {
    fetchPUT: putActiveInactiveOperator,
    requestLoading: loadingPutActiveOperator,
    requestData: putActiveOperatorResponse,
    requestSuccess: successPutOperator,
  } = useCallAxios({ context: "operatorsActiveInactive" });

  const handleSelectedOperator = (id) => {
    history.push(`/operadores?editar=${id}`);
  };

  const handleActiveOperator = (id) => {
    try {
      putActiveInactiveOperator({ id, afterID: "active" });
      addToast({
        title: "Successo",
        description: "Operador ativado com sucesso.",
        type: "success",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleInactiveOperator = (id) => {
    try {
      putActiveInactiveOperator({ id, afterID: "inactive" });
      addToast({
        title: "Successo",
        description: "Operador desativado com sucesso.",
        type: "success",
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedOperator) {
      handleTab("operators-edit");
    }
  }, [selectedOperator]);

  useEffect(() => {
    const getParams = () => {
      const params = new URLSearchParams(history.location.search);
      const operatorFiltered = operatorsList.find(
        (item) => item.id === params.get("editar")
      );
      if (operatorFiltered) {
        setSelectedOperator(operatorFiltered);
      }
    };
    if (operatorsList) {
      getParams();
    }
  }, [operatorsList, history.location.search]);

  const {
    fetchGET: fetchOperatorsList,
    requestLoading: loading,
    requestData: operatorsListResponse,
  } = useCallAxios({ context });

  useEffect(() => {
    fetchOperatorsList({});
  }, []);

  useEffect(() => {
    if (operatorsListResponse.data) {
      setOperatorsList(() => {
        if (subAccountId) {
          return operatorsListResponse.data.filter(
            (item) => item.subAccountId === subAccountId
          );
        }
        return operatorsListResponse.data;
      });
    }
  }, [operatorsListResponse]);

  return {
    loading,
    tab,
    handleTab,
    operatorsList,
    selectedOperator,
    fetchOperatorsList,
    handleSelectedOperator,
    handleActiveOperator,
    handleInactiveOperator,
  };
};

export const Operators = ({}) => {
  const classes = useStyles();
  const {
    loading,
    tab,
    operatorsList,
    fetchOperatorsList,
    handleTab,
    handleSelectedOperator,
    handleActiveOperator,
    handleInactiveOperator,
    selectedOperator,
  } = useOperators({});

  return (
    <>
      <ModalLoader loading={loading} text="Carregando informações" />

      <div className={classes.container}>
        <ScreenGreeting
          title="Operadores"
          subTitle="Aqui você poderá realizar a gestão dos seus operadores."
        />
        <TabContainer>
          <TabButton
            selected={tab === "operators-list"}
            onClick={() => {
              handleTab("operators-list");
            }}
          >
            Listar operadores
          </TabButton>
          <TabButton
            selected={tab === "operators-add"}
            onClick={() => {
              handleTab("operators-add");
            }}
          >
            Adicionar operador
          </TabButton>
        </TabContainer>

        {tab === "operators-list" && (
          <Paper className={classes.paper} elevation={3}>
            <OperatorsTableList
              operatorsList={operatorsList}
              handleSelectedOperator={handleSelectedOperator}
              handleActiveOperator={handleActiveOperator}
              handleInactiveOperator={handleInactiveOperator}
            />
          </Paper>
        )}

        {tab === "operators-add" && (
          <Paper className={classes.paper} elevation={3}>
            <OperatorsForm
              handleTab={handleTab}
              fetchOperatorsList={fetchOperatorsList}
            />
          </Paper>
        )}
        {tab === "operators-edit" && (
          <Paper className={classes.paper} elevation={3}>
            <OperatorsForm
              operatorToEdit={selectedOperator}
              handleTab={handleTab}
              fetchOperatorsList={fetchOperatorsList}
            />
          </Paper>
        )}
      </div>
    </>
  );
};

const TipoUser = {
  admin: "Administrador",
  user: "Usuário",
  subAccount: "Operador",
};

export const OperatorsTableList = ({
  operatorsList,
  handleActiveOperator,
  handleInactiveOperator,
  isHiddenSubAccountLink,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const tooltipOpen = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left"></TableCell>
            <TableCell align="left">Nome</TableCell>
            <TableCell align="left">E-mail</TableCell>
            <TableCell align="left">Perfil</TableCell>
            {!isHiddenSubAccountLink && (
              <TableCell align="left">Ponto de venda</TableCell>
            )}

            <TableCell align="center">Data de criação</TableCell>
            <TableCell align="center">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array(operatorsList).length === 0 ? (
            <TableRow key={index}>
              <TableCell align="left" width={10} colSpan={10}>
                Nenhum operador foi adicionado
              </TableCell>
            </TableRow>
          ) : (
            <>
              {operatorsList?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="left" width={10}>
                    <IconButton id={row.id} onClick={handleClick}>
                      <MoreVert />
                    </IconButton>
                    <Menu
                      sx={{
                        display: anchorEl?.id !== row.id ? "none" : "block",
                      }}
                      key={index + row.id}
                      id="long-menu"
                      MenuListProps={{
                        "aria-labelledby": "long-button",
                      }}
                      anchorEl={anchorEl}
                      open={tooltipOpen}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          width: "25ch",
                          zIndex: 9999999999,
                        },
                      }}
                    >
                      {/* <MenuItem
                        onClick={() => {
                          handleSelectedOperator(row.id);
                          handleClose();
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                          }}
                        >
                          <Edit fontSize="inherit" />
                          Editar
                        </Box>
                      </MenuItem> */}
                      <MenuItem
                        onClick={() => {
                          if (row.isActive) {
                            handleInactiveOperator(row.id);
                          } else {
                            handleActiveOperator(row.id);
                          }
                          handleClose();
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                          }}
                        >
                          {!row.isActive ? (
                            <>
                              <ToggleOn fontSize="inherit" /> Ativar
                            </>
                          ) : (
                            <>
                              <ToggleOff fontSize="inherit" /> Inativar
                            </>
                          )}
                        </Box>
                      </MenuItem>
                    </Menu>
                  </TableCell>
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="left">{row.email}</TableCell>
                  <TableCell align="center">{TipoUser[row.profile]}</TableCell>
                  {!isHiddenSubAccountLink && (
                    <TableCell align="center">
                      {row.subAccountId ? (
                        <MuiLink 
                          component={Link}
                          to={`/pontos-de-venda/${row.subAccountId}`}
                        >
                          ver ponto de venda <FiExternalLink />
                        </MuiLink>
                      ) : (
                        "---"
                      )}
                    </TableCell>
                  )}

                  <TableCell align="center">
                    {new Date(row.createdAt).toLocaleDateString()}
                  </TableCell>
                  <TableCell align="center">
                    {row.isActive ? (
                      <Chip label="Ativo" color="primary" />
                    ) : (
                      <Chip label="Inativo" color="error" variant="outlined" />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </>
          )}
        </TableBody>
      </Table>
    </>
  );
};

const OperatorsForm = ({ handleTab, fetchOperatorsList, operatorToEdit }) => {
  const [name, handleChangeName] = useState(operatorToEdit?.name || "");
  const [email, handleChangeEmail] = useState(operatorToEdit?.email || "");
  const [phone, handleChangePhone] = useState(operatorToEdit?.phone || "");
  const [role, handleChangeRole] = useState(operatorToEdit?.role || "admin");
  const [subAccountId, handleChangeSubAccountId] = useState(
    operatorToEdit?.subAccountId || ""
  );

  const { addToast } = useToast();

  const [errors, setErrors] = useState([]);

  const context = "operatorsCreate";

  const {
    handleGET: fetchSubAccountsGET,
    loading: loadingSubAccounts,
    state: subAccounts,
  } = useSubAccountsList();

  const {
    fetchPOST: postOperatorsCreate,
    requestLoading: loading,
    requestData: operatorsCreateResponse,
    requestSuccess: isSuccessResponse,
  } = useCallAxios({ context });

  const validationSchema = object().shape({
    name: string().required("O campo Nome é obrigatório."),
    email: string()
      .email("Insira um e-mail valido.")
      .required("O campo E-mail é obrigatório."),
    phone: string()
      .min(11, "Telefone deve contar 11 dígitos")
      .required("O campo Telefone é obrigatório."),
    role: string().required("O campo Tipo de usuário é obrigatório."),
  });

  const onSubmit = async () => {
    try {
      const form = { name, email, phone, role, subAccountId };
      await validationSchema.validate(form);
      postOperatorsCreate({ body: form });
      setErrors([]);
    } catch (err) {
      console.log({ err });
      setErrors(err.errors);
    }
  };

  useEffect(() => {
    if (isSuccessResponse.POST) {
      addToast({
        title: "Successo",
        description: "Novo operador criado com sucesso.",
        type: "success",
      });
      handleTab("operators-list");
      fetchOperatorsList({});
    }
  }, [isSuccessResponse.POST]);

  useEffect(() => {
    fetchSubAccountsGET({});
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Nome"
          name="name"
          value={name}
          onChange={(e) => handleChangeName(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="E-mail"
          name="email"
          value={email}
          onChange={(e) => handleChangeEmail(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Telefone"
          name="phone"
          value={phone}
          onChange={(e) => handleChangePhone(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="tipo-usuario">Tipo de usuário</InputLabel>
          <Select
            labelId="tipo-usuario"
            id="tipo-usuario"
            value={role}
            label="Tipo de usuário"
            onChange={(e) => handleChangeRole(e.target.value)}
          >
            <MenuItem value="admin">Administrador</MenuItem>
            <MenuItem value="user">Usuário</MenuItem>
            <MenuItem value="subAccount">Operador</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {role === "subAccount" && (
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="subconta-tipo">Ponto de venda</InputLabel>
            <Select
              labelId="subconta-tipo"
              id="subconta-tipo"
              value={subAccountId}
              label="Ponto de venda"
              onChange={(e) => handleChangeSubAccountId(e.target.value)}
            >
              {subAccounts.map((subAccount, i) => {
                return (
                  <MenuItem value={subAccount.subAccountId} key={i}>
                    {subAccount.name} - {subAccount.subAccountId}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12}>
        {errors &&
          errors.map((err) => (
            <Typography variant="subtitle2" color="red">
              {err} <br />
            </Typography>
          ))}
      </Grid>
      <Grid item xs={4}>
        <Button onClick={onSubmit}>Criar</Button>
      </Grid>
    </Grid>
  );
};
