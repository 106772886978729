import { ProfileMenu } from "../ProfileMenu";
import {
  ButtonsContainer,
  Clock,
  CloseModalButton,
  Container,
  HeaderMainContent,
  LoadingContainer,
  LogoutButton,
  ModalContainer,
  RestoreSessionButton,
  TimerContainer,
  WarningSvg,
  AbrirFecharMenu
} from "./styles";
import { useSessionTimer } from "../../../Hooks/useSessionTimer";
import { CircularProgress, Dialog, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Close, Warning } from "@mui/icons-material";
import { useRestoreSession } from "../../../Hooks/useRestoreSession";
import { useToast } from "../../../Hooks/toast";
import moment from "moment";

export const HeaderContent = ({ logout, isDisabled, accountData }) => {
  const { addToast } = useToast();
  const [openModal, setOpenModal] = useState(false);
  const { countdown, formattedTimestamp } = useSessionTimer();
  const { handleRestoreSession, loading, success } = useRestoreSession();

  const onClose = () => {
    setOpenModal(false);
  };
  const onClickFecharMenu = () => {
    const elements = Array.from(document.getElementsByClassName("MuiDrawer-root") as HTMLCollectionOf<HTMLElement>);
    const header= document.querySelectorAll("header.MuiPaper-root")[0] as HTMLElement;
    const button= document.getElementById("buttonclosemenu") as HTMLElement;
    for(let i=0;i<elements.length;i++){
      elements[i].style.display =  elements[i].style.display !="none"?"none":"block";

      if(elements[i].style.display =="none"){
        header.style.left="0";
        header.style.width="100%!important";
        button.innerHTML="Abrir menu";
      }
      else{
        header.style.left="auto";
        header.style.width="calc(100% - 340px) !important";
        button.innerHTML="Fechar menu";
      }
    }
  };

  useEffect(() => {
    if (countdown <= 0) {
      setOpenModal(true);
    }
  }, [countdown]);

  useEffect(() => {
    if (loading === false && success === true) {
      setOpenModal(false);
      addToast({
        title: "Sua sessão foi renovada!",
        description: "Sessão renovada com sucesso.",
        type: "info",
      });
    }
  }, [loading, success]);
  return (
    <>
      <Container>
        <HeaderMainContent>
          <AbrirFecharMenu onClick={onClickFecharMenu} id="buttonclosemenu">
          Fechar menu
          </AbrirFecharMenu>
          <TimerContainer>
            Sua sessão irá expirar em: { formattedTimestamp }
          </TimerContainer>
          <ProfileMenu logout={logout} accountData={accountData} />
        </HeaderMainContent>
      </Container>
      <Dialog open={openModal} onClose={onClose}>
        <ModalContainer>
          <CloseModalButton onClick={onClose}>
            <span>Fechar</span>
            <Close />
          </CloseModalButton>
          <p>Seu tempo de sessão expirou!</p>
          <WarningSvg>
            <Warning />
          </WarningSvg>
          <ButtonsContainer>
            {loading ? (
              <LoadingContainer>
                <CircularProgress size={"1.5rem"} />
              </LoadingContainer>
            ) : (
              <RestoreSessionButton onClick={handleRestoreSession}>
                Renovar sessão
              </RestoreSessionButton>
            )}
            <LogoutButton onClick={logout}>Ok, sair</LogoutButton>
          </ButtonsContainer>
        </ModalContainer>
      </Dialog>
    </>
  );
};
