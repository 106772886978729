import { FormEvent, useContext, useState } from "react";
import { Button, CompanyLogo, Input } from "../../../../Components/Atoms";
import { Form } from "@unform/web";
// import { Container } from "../style";
import api from "../../../../Services/api";
import { MyFirstAccessContext } from "..";
import { useHistory } from "react-router-dom";
import { useToast } from "../../../../Hooks/toast";
import { TextField, Typography } from "@mui/material";
import { VerificationContainer } from "../../VerificationCode/styles";

export const SendEmail = () => {

    const history = useHistory();

    const { sendVerificationCode } = useContext(MyFirstAccessContext);

    const { addToast } = useToast();

    const [email, setEmail] = useState('');

    const [error, setError] = useState('');

    const handleSubmit = async (e: FormEvent) => {

        e.preventDefault();

        if(!email) return setError('Email obrigatório');
        if(!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) return setError('Email inválido');

        setError('');

        const isSuccessfulResponse = await sendVerificationCode(email);

        if(isSuccessfulResponse === true) 
            return history.push('/auth/my-first-access/validate-code');

       else if(isSuccessfulResponse === false) {
            return addToast({
                title: 'Erro',
                description: 'Erro ao enviar código de validação',
                type: 'error'
            });
        }

        return addToast({
            title: 'Erro',
            description: isSuccessfulResponse,
            type: 'error'
        });
    }

    return (
        <VerificationContainer>
            <CompanyLogo width={200} height={60} />
            <Typography mb={2}>Para realizar o seu primeiro acesso, insira seu email</Typography>
            <form onSubmit={handleSubmit}>
                <TextField 
                    type="email"
                    name="email"
                    helperText={error}
                    error={!!error}
                    fullWidth
                    sx={{ mb: 2 }}
                    placeholder="Email"
                    onChange={e => setEmail(e.currentTarget.value)}
                />

                <Button type="submit">
                    Avançar
                </Button>
            </form>
        </VerificationContainer>
    )
}