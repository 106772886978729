import moment from "moment";
import { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";

interface SessionTimerContextData {
  handleSetTimer: (dateTimerExpire: string) => void;
  countdown: number
  formattedTimestamp: string
}

const SessionTimerContext = createContext<SessionTimerContextData>(
  {} as SessionTimerContextData
);

const sessionKey = "@Session:expires";

const SessionTimerProvider = ({ children }) => {

  const expiresDate = +(sessionStorage.getItem(sessionKey) || '0');

  const [countdown, setCountdown] = useState(expiresDate - (new Date()).getTime());

  const handleSetTimer = (dt: string) => {
    const newExpiresDate = new Date(dt).getTime();
    sessionStorage.setItem(sessionKey, newExpiresDate.toString());
    
    setCountdown(
      newExpiresDate - (new Date()).getTime()
    );
  };

  const formattedTimestamp = useMemo(() => {

    let totalSeconds = countdown / 1000;

    let hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;

    const minutes = Math.floor(totalSeconds / 60);
    const seconds = Math.floor(totalSeconds % 60);

    return [
      hours.toString().padStart(2, '0'),
      minutes.toString().padStart(2, '0'),
      seconds.toString().padStart(2, '0'),
    ].join(':');
  }, [countdown])

  useEffect(() => {
    const timeoutRef = setTimeout(() => {
      const currentDate = new Date().getTime();

      const nextTimestamp = expiresDate - currentDate;

      if(nextTimestamp >= 0) {
        setCountdown(nextTimestamp);
      }   
    }, 1000);

    return () => clearTimeout(timeoutRef);
  }, [countdown]);

  return (
    <SessionTimerContext.Provider value={{ handleSetTimer, formattedTimestamp, countdown }}>
      {children}
    </SessionTimerContext.Provider>
  );
};

function useSessionTimer() {
  const context = useContext(SessionTimerContext);

  return context;
}

export { SessionTimerProvider, useSessionTimer };
