import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, TextField } from "@mui/material";
import { useRef, useState, useImperativeHandle, forwardRef, FormEvent, ChangeEvent } from "react"
import api from "../../../../Services/api";
import { useToast } from "../../../../Hooks/toast";


export type OrginizerFormRef = {
    openOrganizer: (props: { name: string, subAccountId: string, isActive: boolean }) => void;
}

export const OrganizerForm = forwardRef<OrginizerFormRef,{ onSucess: VoidFunction }>(({ onSucess = () => {} }, ref) => {
    const [open, setOpen] = useState(false);

    const [subAccount, setSubAccount] = useState({
        subAccountId: '',
        name: '',
        isActive: true
    });

    const { addToast } = useToast();

    const [isLoading, setIsLoading] = useState(false);

    useImperativeHandle(ref, () => ({
        openOrganizer({ name = '', subAccountId = '', isActive = true }) {
            setSubAccount({
                name,
                subAccountId,
                isActive
            })
            setOpen(true);
        }
    }), [open])

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.type == 'checkbox' ? e.currentTarget.checked : e.currentTarget.value;

        setSubAccount(oldSubAccount => ({ ...oldSubAccount, [name]: value}));
    }

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        try {
            setIsLoading(true);

            if(subAccount.subAccountId) {
                await api.put(`/subaccount/${subAccount.subAccountId}`, {
                    name: subAccount.name,
                    isActive: subAccount.isActive
                });
            }
            else {
                await api.post('/subaccount', {
                    name: subAccount.name
                });
            }

            addToast({
                title: "Sucesso",
                description: "Ponto de venda salvo com sucesso",
                type: 'success'
            })
    
            setOpen(false);            
        }
        finally {
            setIsLoading(false);
            onSucess();
        }

    }

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>{subAccount.subAccountId ? 'Editar PV' : 'Adicionar PV'}</DialogTitle>
            <DialogContent sx={{ width: '500px' }}>
                <Box onSubmit={handleSubmit} id="frmSubAccount" component='form' mt={2}>
                    <TextField size="small" fullWidth name="name" value={subAccount.name} onChange={handleChange} label="Nome" />
                    <FormControlLabel
                        label="Ativo?"
                        control={
                            <Checkbox
                                disabled={!subAccount.subAccountId}
                                name="isActive"
                                checked={subAccount.isActive}
                                onChange={handleChange}
                            />
                        }
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button form="frmSubAccount" type='submit' disabled={!subAccount.name || isLoading} variant="text" color="primary">Salvar</Button>
            </DialogActions>
        </Dialog>
    )
})