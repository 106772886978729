import { useCallback, useEffect, useState } from "react";
import { formatInputValue } from "../../../Utils/formatInputValue";
import { convertToCents, convertToUnity } from "../../../Utils/formatters";
import { Input } from "../../Atoms";
import { SelectOptionButtons } from "../SelectOptions";
import { CustomInput } from "./styles";

export const InputMoney = ({ hasInput = true, refreshValue = () => {} }) => {
  const [linkValue, setLinkValue] = useState(0);
  const options = [
    {
      key: 1,
      title: "+ 1",
    },
    {
      key: 10,
      title: "+ 10",
    },
    {
      key: 50,
      title: "+ 50",
    },
    {
      key: 100,
      title: "+ 100",
    },
    {
      key: 0,
      title: "limpar",
    },
  ];

  const handleValue = useCallback(
    (e) => {
      let toSum = convertToCents(e);
      let value = convertToCents(linkValue);
      value = value + toSum;

      if (toSum === 0) {
        refreshValue(toSum);
        setLinkValue(formatInputValue("0"));
      } else {
        setLinkValue(formatInputValue(value.toString()));
        refreshValue(convertToUnity(value));
      }
    },
    [linkValue]
  );

  const handleCustomValue = useCallback(
    (e) => {
      let value = e;
      setLinkValue(formatInputValue(value.toString()));
    },
    [linkValue]
  );

  useEffect(() => {
    refreshValue(linkValue);
  }, [linkValue]);

  return hasInput ? (
    <div
      style={{
        padding: "0px 0px 20px 0px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div>
        <Input
          style={{ fontSize: 20, width: '100%' }}
          value={linkValue}
          
          onChange={(e) => {
            setLinkValue(formatInputValue(e.currentTarget.value));
          }}
          name="value"
          type="text"
          maxLength={10}
          placeholder="Valor (R$)"
          aria-label="amount-input"
        />
      </div>
      <div>
        <SelectOptionButtons
          width={150}
          options={options}
          refreshOptionSelected={(e) => handleValue(e)}
        />
      </div>
    </div>
  ) : (
    <>
      <CustomInput
        style={{ fontSize: 20, width: '100%' }}
        value={linkValue}
        onChange={(e) => {
          handleCustomValue(e.currentTarget.value);
        }}
        type="text"
        maxLength={10}
        placeholder="Valor (R$)"
      />
      <SelectOptionButtons
        width={150}
        options={options}
        refreshOptionSelected={(e) => handleValue(e)}
      />
    </>
  );
};
