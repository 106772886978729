import styled from "styled-components";
import { Grid, Card, CircularProgress } from "@mui/material";

export const Container = styled(Card)`
  box-shadow: none !important;
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.12);
  width: 340px;
  padding: 0px 30px 10px 25px;

  p {
    font-size: 14;
    color: ${e => e.theme.primary.original};
    font-weight: 700;
  }
`;

export const GridComponent = styled(Grid)`
  svg {
    font-size: 32px;
    color: ${(props) => props.theme.primary.original};
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px;
    cursor: pointer;
    :hover {
      border-color: ${(props) => props.theme.primary.original};
      color: ${(props) => props.theme.primary.original};
      transition: 0.4s;
      transform: scale(1.1);
    }
  }

  .balance {
    margin-bottom: 1rem;
    align-items: center;

    p {
      height: fit-content;
      margin-bottom: 0;
    }
  }
`;

export const CircularProgressWithoutBorder = styled(CircularProgress)`
  && {
    margin-left: 2rem;
    svg {
      border: none;
    }
  }
`;
