import {
  accountRoute,
  dashboardRoute,
  defaultRoute,
  extractRoute,
  // contactsRoute,
  pixRoute,
  pixPaymentRoute,
  pixSales,
  pixDetails,
  forgotPassword,
  loginRoute,
  receiptRoute,
  transferRoute,
  refoundsRoute,
  ticketsRoute,
  pixTransferRoute,
  tokenVerificationRoute,
  paymentsRoute,
  operatorsRoute,
  organizersRoute,
  resetPassword,
  organizerDetailRoute,
} from "./Routes";

export const physicalPerson = {
  private: [
    dashboardRoute,
    extractRoute,
    pixRoute,
    pixSales,
    pixDetails,
    refoundsRoute,
    transferRoute,
    paymentsRoute,
    ticketsRoute,
    pixTransferRoute,
    receiptRoute,
    pixPaymentRoute,
    organizersRoute,
    // contactsRoute,
    accountRoute,
    operatorsRoute,
    organizerDetailRoute,
    defaultRoute,
  ],
  public: [tokenVerificationRoute, forgotPassword, loginRoute, resetPassword],
};

export const menuRoutes = [
  dashboardRoute,
  extractRoute,
  pixRoute,
  transferRoute,
  refoundsRoute,
  // contactsRoute,
  paymentsRoute,
  ticketsRoute,

  receiptRoute,
  organizersRoute,
  accountRoute,
  operatorsRoute,
];

export const companyPerson = {
  private: [
    dashboardRoute,
    extractRoute,

    pixRoute,
    transferRoute,
    refoundsRoute,
    paymentsRoute,
    pixTransferRoute,
    pixSales,
    pixDetails,
    pixPaymentRoute,
    receiptRoute,
    ticketsRoute,
    organizersRoute,
    // contactsRoute,
    accountRoute,
    operatorsRoute,
    defaultRoute,
  ],
  public: [tokenVerificationRoute, forgotPassword, loginRoute],
};
